import { render, staticRenderFns } from "./BedList.vue?vue&type=template&id=6f5366b2&scoped=true"
import script from "./BedList.vue?vue&type=script&lang=js"
export * from "./BedList.vue?vue&type=script&lang=js"
import style0 from "./BedList.vue?vue&type=style&index=0&id=6f5366b2&prod&lang=scss&scoped=true"
import style1 from "./BedList.vue?vue&type=style&index=1&id=6f5366b2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f5366b2",
  null
  
)

export default component.exports